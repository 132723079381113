import React from 'react';
import Main from './components/Main/Main';
import About from './components/About/About';
import Services from './components/Services/Services';
import Team from './components/Team/Team';
import Price from './components/Price/Price';
import Gallery from './components/Gallery/Gallery';
import Footer from './components/Footer/Footer';
import useMediaQuery from './hooks/useMediaQuery'; // Импортируем хук

import './App.css';

const App = () => {
  const isDesktop = useMediaQuery('(min-width: 992px)'); // Проверяем, десктоп ли это
  return (
      <div>
        <Main />
        <About />
        <Services />
        <Team />
        {isDesktop && <Price />} {/* Рендерим Price только на десктопе */}
        {isDesktop && <Gallery />} {/* Рендерим Gallery только на десктопе */}
        <Footer />
      </div>

  );
};

export default App;
import { useState, useEffect } from 'react';

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);

    // Устанавливаем начальное значение
    setMatches(media.matches);

    // Добавляем слушатель изменений
    media.addEventListener('change', listener);

    // Убираем слушатель при размонтировании
    return () => media.removeEventListener('change', listener);
  }, [query]);

  return matches;
};

export default useMediaQuery;
import React, { useState } from "react";
import styles from "./Services.module.scss";
import { useTranslation } from "react-i18next"; // Используем `react-i18next` для перевода
import { FaFacebookF, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';

const Services = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return (
    <main className={styles.services} id="services">
        <div className={styles.emptyCell}></div>
        <img src="/12.jpg" alt="main" className={styles.servicesSmall}/>
        <div className={styles.servicesTextContainer}>
          <h1>{t("Services.h1")}</h1>
          <h3>{t("Services.h3")}</h3>
          <div className={styles.socialMedia}>
              <a href="https://www.facebook.com/p/Veterinary-Clinic-911-61556920859097/?locale=lv_LV" target="_blank" rel="noopener noreferrer">
                <FaFacebookF />
              </a>
              <a href="https://t.me/+995591175185" target="_blank" rel="noopener noreferrer">
                <FaTelegramPlane />
              </a>
              <a href="https://wa.me/995591175185" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp />
              </a>
          </div>
        </div>

        <div className={styles.cards}>
          <h1>{t("Cards.Title")}</h1>
          <ul className={styles.cardsList}>
            <li>
              <h3 className={styles.cardTitle}>{t("Cards.Hospital.title")}</h3>
            </li>
            <li>
              <h3 className={styles.cardTitle}>{t("Cards.Laboratory.title")}</h3>
            </li>
            <li>
              <h3 className={styles.cardTitle}>{t("Cards.Surgery.title")}</h3>
            </li>
            <li>
              <h3 className={styles.cardTitle}>{t("Cards.Rentgen.title")}</h3>
            </li>
            <li>
              <h3 className={styles.cardTitle}>{t("Cards.Diagnostics.title")}</h3>
            </li>
            <li>
              <h5 className={styles.cardTitle}>{t("Cards.Endoscope.subtitle")}</h5>
              <h3 className={styles.cardTitle}>{t("Cards.Endoscope.title1")}</h3>
              <h3 className={styles.cardTitle}>{t("Cards.Endoscope.title2")}</h3>
            </li>
          </ul>
        </div>

      <img src="/1.jpg" alt="main" className={styles.servicesBig}/>
    </main>
  );
};

export default Services;

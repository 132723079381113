import React, { useContext, useEffect, useState } from 'react';
import styles from './Main.module.scss';
import i18n from "../../i18n";
import { LanguageContext } from '../../LanguageContext';
import { useTranslation } from "react-i18next";
import { MdLocationPin } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import useMediaQuery from '../../hooks/useMediaQuery';
import { CgMenuRound } from "react-icons/cg";

const Main = () => {
    console.log('Main component rendered'); // Проверяем, рендерится ли компонент

    const { t } = useTranslation();
    const { language, switchLanguage } = useContext(LanguageContext);
    const [isNavOpen, setIsNavOpen] = useState(false);

    const isDesktop = useMediaQuery('(min-width: 1200px)');
    console.log('isDesktop:', isDesktop); // Проверяем значение isDesktop

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handleLanguageChange = (event) => {
        switchLanguage(event.target.value);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isNavOpen && !event.target.closest(`.${styles.nav}`) && !event.target.closest(`.${styles.menuToggle}`)) {
                setIsNavOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isNavOpen]);

    return (
        <main className={styles.main}>
            <img src="/logo.jpg" alt={t('logoAlt')} className={styles.logo} />
            <div onClick={toggleNav}>
                <CgMenuRound className={styles.menuToggle} />
            </div>

            <nav className={`${styles.nav} ${isNavOpen ? styles.active : ''}`}>
                <ul>
                    <li><a href="#services">{t('services')}</a></li>
                    <li><a href="#team">{t('team')}</a></li>
                    {isDesktop && <li><a href="#gallery">{t('gallery')}</a></li>}
                    {isDesktop && <li><a href="#prices">{t('prices')}</a></li>}
                    <li><a href="#footer">{t('contact')}</a></li>
                    <li>
                        <select
                            value={language}
                            onChange={handleLanguageChange}
                            className={styles.languageSwitcher}
                        >
                            <option value="en">English</option>
                            <option value="ru">Русский</option>
                        </select>
                    </li>
                </ul>
            </nav>
            {isNavOpen && (
                <div className={styles.overlay} onClick={toggleNav}></div>
            )}
            <img src="/8.jpg" alt="main" className={`${styles.imageContainer}`} />
            <div className={styles.container}>
                <div className={`${styles.block} ${styles.block2} ${styles.textContainer}`}>
                    <h2>{i18n.t('Main.h2', { lng: language })}</h2>
                    <h1 dangerouslySetInnerHTML={{ __html: i18n.t('Main.h1', { lng: language }) }} />
                    <h3>{i18n.t('Main.h3', { lng: language })}</h3>
                </div>
            </div>
            <div className={`${styles.contactInfo} ${styles.block} ${styles.block1}`}>
                <p>{i18n.t('Main.p', { lng: language })}</p>
                <a
                    href="https://maps.app.goo.gl/SQUmNg96vCmsRe8R9"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <MdLocationPin /> Agmashenebeli 30, Poti, Georgia
                </a>
                <a href="tel:+995591175185">
                    <FiPhone /> +995 (591) 175-185
                </a>
            </div>
        </main>
    );
};

export default Main;
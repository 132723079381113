import React from "react";
import styles from "../Team/Team.module.scss";
import { useTranslation } from "react-i18next"; // Используем `react-i18next` для перевода

const Team = () => {
  const { t } = useTranslation();
    return (
        <main className={styles.teamContainer}>
              <img className={styles.teamPhoto1} src="IMG_0543.jpg" alt="Team member Sasha"/>
              <div className={styles.teamText1}>
                  <h2 className={styles.heading}>{t("Team.title")}</h2>
                  <h3>{t("TeamCards.Sasha.title")}</h3>
                  <h4>{t("TeamCards.Sasha.subtitle")}</h4>
              </div>
              <div className={styles.teamText2}>
                  <h3>{t("TeamCards.Dima.title")}</h3>
                  <h4>{t("TeamCards.Dima.subtitle")}</h4>
              </div>
              <img className={styles.teamPhoto2} src="IMG_0792.jpg" alt="Team member Dima"/>
          </main>


    );
  };

  export default Team;
